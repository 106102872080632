<template>
  <!-- 云窗生活 -->
  <div class="contact-us">
    <div class="banner">
      <div class="title">引领行业发展，深度挖掘场景价值需求</div>
    </div>
    <div class="wrapper">
      <!-- 第一个板块 -->
      <div class="first">
        <div class="item">
          <div class="slogan">云窗科技</div>
          <!-- 第一个 -->
          <div class="main">
            <div
              class="right"
            >
              <div class="title">
                {{ pageData[0] && pageData[0].title }}
              </div>
              <div class="box_wrapper" style="height: 400px">
                <div
                  class="box"
                  v-for="item in pageData[0].newsList"
                  :key="item.id"
                >
                  <div
                    class="title_samll"
                    style="
                      font-size: 18px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="content"
                    style="font-size: 14px; font-weight: 300;line-height:2"
                  >
                    {{ item.summary }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="left"
            >
              <img
                :src="pageData[0].coverUrl"
                alt=""
                height="500px"
                width="550px"
              />
            </div>
          </div>
          <!-- 第二个 -->
          <div class="main">
            <div
              class="left"
            >
              <img
                :src="pageData[1].coverUrl"
                alt=""
                width="115%"
                height="400px"
                style="transform: translate(-120px, 0)"
              />
            </div>
            <div
              class="right "
            >
              <div class="title">
                {{ pageData[1] && pageData[1].title }}
              </div>
              <div class="box_wrapper" style="height: 350px">
                <div
                  class="box"
                  v-for="item in pageData[1].newsList"
                  :key="item.id"
                >
                  <div
                    class="title_samll"
                    style="
                      font-size: 18px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="content"
                    style="font-size: 14px; font-weight: 300;line-height:2"
                  >
                    {{ item.summary }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第三个 -->
          <div class="main">
            <div
              class="right "
            >
              <div class="title">
                {{ pageData[2] && pageData[2].title }}
              </div>
              <div class="box_wrapper" style="height: 180px">
                <div
                  class="box"
                  v-for="item in pageData[2].newsList"
                  :key="item.id"
                >
                  <div
                    class="title_samll"
                    style="
                      font-size: 18px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    "
                  >
                    <!-- 信赖与沟通 -->
                  </div>
                  <div
                    class="title_samll"
                    style="
                      font-size: 18px;
                      font-weight: 500;
                      margin-bottom: 15px;
                    "
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="content"
                    style="font-size: 14px; font-weight: 300;line-height:2"
                  >
                    {{ item.summary }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="left"
            >
              <img :src="pageData[2].coverUrl" alt="" width="100%" />
            </div>
          </div>
        </div>
      </div>
      <!-- 第二个板块 -->
      <div class="second">
        <div class="item">
          <div class="slogan">云窗科技</div>
          <div class="carousel">
            <el-carousel class="carouselBox">
              <el-carousel-item
                v-for="item in pageData[3].newsList"
                :key="item.id"
              >
                <img :src="item.coverUrl" alt="" height="400px" width="100%" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "@/components/footer/footer.vue";
import { yunLife } from "@/api/contactUs.js";
export default {
  metaInfo: {
      title: '云窗科技', // set a title
      meta: [{                 // set meta
        name: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会',
        content: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: 'http://yunchuangtech.com/'
      }]
   },
  components: { Foot },

  data() {
    return {
      imgList: [
        // {
        //   id: 1,
        //   imgUrl: "ex_img4.png"
        // },
      ],
      pageData: [
        { newsList: [] },
        { newsList: [] },
        { newsList: [] },
        { newsList: [] },
      ],
    };
  },
  created() {
    this.getData();
  },
  
  methods: {
    async getData() {
      let { data: res } = await yunLife();
      this.pageData = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
